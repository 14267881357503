import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserData } from '../data/users';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class UserService extends UserData {

  constructor(private cookies: CookieService) {
    super();
  }

  // 默认用户信息
  private userInfo = {
     name: '管理员',
     color: '#3366ff',
     realname: '管理员',
     email: 'admin@jxrckj.com',
  };

  // 获取用户信息
  getUsers(): Observable<any> {
    this.userInfo.name = this.cookies.get('userName');
    this.userInfo.color = this.cookies.get(this.userInfo.name);

    return observableOf(this.userInfo);
  }

  // 设置用户信息
  setUsers(
    userinfo: {
      name: string,
      realname: string,
      email: string,
    },
  ) {
    this.cookies.set('userName', userinfo.name, null, '/');
    
    let color = '';
    if (this.cookies.check(userinfo.name)) {
      color = this.cookies.get(userinfo.name);
    }
    else {
      color = getRandomColor();
      this.cookies.set(userinfo.name, color,
        new Date(getUTCTime() + 1000 * 3600 * 24 * 365), '/');
    }
    
    this.userInfo.name = userinfo.name;
    this.userInfo.realname = userinfo.realname;
    this.userInfo.email = userinfo.email;
    this.userInfo.color = color;
  }
}

// 获取随机颜色
function getRandomColor() {
  return '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substring(5);
}

// 获取当前时间戳
function getUTCTime() {
  const data = new Date();
  return data.getTime() - data.getTimezoneOffset() * 60 * 1000;
}
