import { Observable } from 'rxjs';

export interface User {
  name: string;
  color: string;
  realname: string;
  email: string;
}

export abstract class UserData {
  abstract getUsers(): Observable<User[]>;
  abstract setUsers(userinfo: {
    name: string,
    realname: string,
    email: string,
  });
}
