import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="trademark">
      © 2022 Gsoy
    </span>
    <a href="https://akveo.page.link/8V2f" target="_blank">UI By Akveo</a>
    <!--<div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div>-->
  `,
})
export class FooterComponent {
}
