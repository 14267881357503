<div class="header-container">
  <div class="logo-container">
    <a class="logo" href="#">
      <span>江西睿创环境监控系统</span>
    </a>
  </div>
</div>

<div class="header-container">
  <div class="logo">
    <span>{{ currentTime }}</span>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
  </nb-actions>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary" style="padding-left: 10px;">
    <nb-option *ngFor="let theme of themes" [value]="theme.value">{{ theme.name }}</nb-option>
  </nb-select>
</div>
