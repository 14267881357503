import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbToastrService,
} from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { UserInfoDialogComponent } from './user-info-dialog/user-info-dialog.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  user: any;

  // 主题列表
  themes = [
    {
      value: 'default',
      name: '明亮',
    },
    {
      value: 'dark',
      name: '黑暗',
    },
    {
      value: 'cosmic',
      name: '宇宙',
    },
    {
      value: 'corporate',
      name: '扁平',
    },
  ];

  currentTheme = 'dark';

  userMenu = [{ title: '个人信息' }, { title: '退出登录', link: 'auth/login' }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private cookies: CookieService,
    private dialogService: NbDialogService,
    private http: HttpClient,
    private toastrService: NbToastrService,
  ) {}

  // 初始化主题和用户信息
  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    if (this.cookies.check('currentTheme')) {
      this.themeService.changeTheme(this.cookies.get('currentTheme'));
    }

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userInfo: any) => (this.user = userInfo));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe((themeName) => {
        this.currentTheme = themeName;
        this.cookies.set('currentTheme', themeName, new Date(getUTCTime() + 1000 * 3600 * 24 * 365), '/');
      });

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-context-menu'),
        map(({ item: { title } }) => title),
        takeUntil(this.destroy$),
      )
      .subscribe((title) => {
        switch (title) {
          case '个人信息':
            this.openUserInfoDlg();
            break;
          case '退出登录':
            this.logout();
            break;
        }
      });
  }

  // 打开用户信息对话框
  openUserInfoDlg() {
    this.dialogService.open(
      UserInfoDialogComponent,
      {
        closeOnBackdropClick: false,
      },
    );
  }

  // 退出登录
  logout() {
    this.http.put(
      '/api/auth/logout', {},
    ).subscribe((response) => {
      if (response['code'] !== 2003) {
        this.showToast('退出登录时出错', response['message'], 'warning');
      }
    });
  }

  // 组件销毁回调
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // 改变主题
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  // 开关侧边栏
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  // 返回首页
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  // 显示提示消息
  positions = NbGlobalPhysicalPosition;
  showToast(title, context, status) {
    const position = this.positions.TOP_LEFT;
    this.toastrService.show(title, context, { position, status });
  }
}

// 获取当前时间戳
function getUTCTime() {
  const data = new Date();
  return data.getTime() - data.getTimezoneOffset() * 60 * 1000;
}
