<nb-card>
  <nb-card-header>个人信息</nb-card-header>
  <nb-card-body>
    <div class="form-group">
      <div>
        <nb-user size="giant" [name]="user.username" [color]="userColor">
        </nb-user>
      </div>
    </div>
    <div class="form-group">
      <label for="inputName" class="label">姓名</label>
      <input type="name" nbInput fullWidth id="inputName"
        placeholder="姓名" [(ngModel)]="user.realname"
        #realname="ngModel" pattern="^[A-Za-z0-9\u4e00-\u9fa5]+$"
        minlength="2" maxlength="10"
        [status]="realname.dirty ? (realname.invalid  ? 'danger' : 'success') : 'basic'"
        [attr.aria-invalid]="realname.invalid && realname.touched ? true : null">
      <ng-container *ngIf="realname.invalid && realname.touched">
        <p class="caption status-danger" *ngIf="realname.errors?.minlength || realname.errors?.maxlength">
          姓名长度至少 2 到 10 个字符
        </p>
        <p class="caption status-danger" *ngIf="!realname.errors?.minlength && realname.errors?.pattern">
          姓名只能输入中文、英文或数字
        </p>
      </ng-container>
    </div>
    <div class="form-group">
      <label for="inputEmail" class="label">邮箱</label>
      <input nbInput
           fullWidth
           type="email"
           [(ngModel)]="user.email"
           #email="ngModel"
           name="email"
           id="inputEmail"
           pattern=".+@.+\..+"
           placeholder="邮箱"
           autofocus
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
          <ng-container *ngIf="email.invalid && email.touched">
            <p class="caption status-danger" *ngIf="email.errors?.pattern">
              请输入正确的邮箱
            </p>
          </ng-container>
    </div>
    <div class="form-group">
      <label for="inputPassword" class="label">密码</label>
      <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           #password="ngModel"
           name="password"
           type="password"
           id="inputPassword"
           pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]+$"
           placeholder="必须包含数字和英文大小写"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           minlength="4"
           maxlength="16"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
      <ng-container *ngIf="password.invalid && password.touched ">
        <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
          密码长度至少 4 到 16 个字符
        </p>
        <p class="caption status-danger" *ngIf="!password.errors?.minlength && password.errors?.pattern">
          密码必须包含数字和英文大小写
        </p>
      </ng-container>
    </div>
    <div class="form-group">
      <label for="inputPasswordConfirm" class="label">确认密码</label>
      <input nbInput
           fullWidth
           [(ngModel)]="user.passwordConfirm"
           #passwordConfirm="ngModel"
           name="passwordConfirm"
           type="password"
           id="inputPasswordConfirm"
           placeholder="再次输入密码"
           [status]="passwordConfirm.dirty ?
           (passwordConfirm.invalid || (passwordConfirm.value != password.value)  ? 'danger' : 'success') : 'basic'"
           minlength="4"
           maxlength="16"
           [attr.aria-invalid]="passwordConfirm.invalid && passwordConfirm.touched ? true : null">
      <ng-container *ngIf="passwordConfirm.touched">
        <p class="caption status-danger" *ngIf="passwordConfirm.value != password.value">
          两次输入密码不相同
        </p>
      </ng-container>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="dismiss" nbButton status="danger" (click)="dismiss()">关闭</button>
    <button nbButton status="success" (click)="save()"
      [disabled]="email.errors || password.errors || passwordConfirm.value != password.value">
      保存
    </button>
  </nb-card-footer>
</nb-card>
