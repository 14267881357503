import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbThemeService,
} from '@nebular/theme';

import { map, takeUntil } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'ngx-view-header',
  styleUrls: ['./view-header.component.scss'],
  templateUrl: './view-header.component.html',
})
export class ViewHeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: '明亮',
    },
    {
      value: 'dark',
      name: '黑暗',
    },
    {
      value: 'cosmic',
      name: '宇宙',
    },
    {
      value: 'corporate',
      name: '扁平',
    },
  ];

  currentTheme = 'dark';

  currentTime = this.formatDate();

  constructor(
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private cookies: CookieService,
  ) {}

  // 初始化主题
  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    if (this.cookies.check('currentTheme')) {
      this.themeService.changeTheme(this.cookies.get('currentTheme'));
    }

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl),
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe((themeName) => {
        this.currentTheme = themeName;
        this.cookies.set('currentTheme', themeName, new Date(this.getUTCTime() + 1000 * 3600 * 24 * 365));
      });

    // 实时更新时间
    this.currentTime = this.formatDate();
    setInterval(() => {
      this.currentTime = this.formatDate();
    }, 1000);
  }

  // 销毁回调
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // 切换主题
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  // 获取当前时间戳
  getUTCTime() {
    const data = new Date();
    return data.getTime() - data.getTimezoneOffset() * 60 * 1000;
  }

  // 格式化时间
  formatDate() {
    const strDate = new Date();
    const strFormat = 'yyyy年MM月dd日 ww\xa0\xa0\xa0HH时mm分ss秒';

    let hours = strDate.getHours();
    let hoursTime = '上午';
    if (hours > 12) {
      hours = hours - 12;
      hoursTime = '下午';
    }

    const dict: any = {
      yyyy: strDate.getFullYear(),
      M: strDate.getMonth() + 1,
      d: strDate.getDate(),
      H: strDate.getHours(),
      m: strDate.getMinutes(),
      s: strDate.getSeconds(),
      MM: ('' + (strDate.getMonth() + 101)).substring(1),
      dd: ('' + (strDate.getDate() + 100)).substring(1),
      ww: strDate.toLocaleString('zh-CN', { weekday: 'long' }),
      HH: hoursTime + ('' + (hours + 100)).substring(1),
      mm: ('' + (strDate.getMinutes() + 100)).substring(1),
      ss: ('' + (strDate.getSeconds() + 100)).substring(1),
    };

    return strFormat.replace(/(yyyy|MM?|dd?|ww?|HH?|ss?|mm?)/g, function() {
      return dict[arguments[0]];
    });
  }
}
