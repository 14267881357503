import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbGlobalPhysicalPosition, NbToastrService  } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserData } from '../../../../@core/data/users';

@Component({
  selector: 'ngx-user-info-dialog',
  templateUrl: 'user-info-dialog.component.html',
  styleUrls: ['user-info-dialog.component.scss'],
})
export class UserInfoDialogComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  
  @Input() title: string;
  @Input() text: string;

  user: any = {};
  sbBackEndUserInfo: any;
  userColor: string;

  constructor(
    protected ref: NbDialogRef<UserInfoDialogComponent>,
    private userService: UserData,
    private http: HttpClient,
    private toastrService: NbToastrService,
  ) {}

  // 初始化 获取用户信息
  ngOnInit() {
    this.http.get(
      '/api/api/v1/anonymous/find/personal/info',
    ).subscribe((response) => {
      if (response['code'] === 2001) {
        this.sbBackEndUserInfo = response['data'];
        
        this.user.username = this.decodeEmoji(this.sbBackEndUserInfo.username);
        this.user.realname = this.sbBackEndUserInfo.realName;
        this.user.email = this.sbBackEndUserInfo.email;
      }
      else this.showToast('获取个人信息失败', response['message'], 'warning');
    });
    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userInfo: any) => (this.userColor = userInfo.color));
  }

  // 关闭对话框
  dismiss() {
    this.ref.close();
  }

  // 保存个人信息
  save() {
    const changedInfo: any = {};

    if (this.user.realname !== this.sbBackEndUserInfo.realName ||
    this.user.email !== this.sbBackEndUserInfo.email) {
      changedInfo.realname = this.user.realname;
      changedInfo.email = this.user.email;
    }
    if (this.user.password !== '' && this.user.password === this.user.passwordConfirm)
      changedInfo.password = this.user.password;
    
    if (JSON.stringify(changedInfo) !== '{}') {
      this.http.put(
        '/api/api/v1/anonymous/update/personal/info',
        changedInfo,
      ).subscribe((response) => {
        if (response['code'] === 2001) {
          this.showToast('完成', '个人信息保存成功', 'success');
        }
        else this.showToast('保存失败', response['message'], 'warning');
      });
    }
    else this.showToast('完成', '个人信息未变动', 'success');

    this.ref.close();
  }

  // 解析emoji
  decodeEmoji(str: string) {
    if (str.search('EMOJI') === 0) {
      str = str.substring(5, str.length);
      let decode = '';
      for (let i = 0; i < str.length; i += 2) {
        decode += '%' + str.substring(i, i + 2);
      }
      return decodeURIComponent(decode);
    }
    else return str;
  }

  // 显示消息提示
  positions = NbGlobalPhysicalPosition;
  showToast(title, context, status) {
    const position = this.positions.TOP_LEFT;
    this.toastrService.show(title, context, { position, status });
  }
}
